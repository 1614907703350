/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState } from 'react'
import { CloseButton, GlobalStyles, Icon, NotificationContainer, Tooltip } from '../../../../../../components/filter-element/styles'

function Notification({ isVisible, setVisible }: { isVisible: boolean; setVisible: () => void; }) {

	if (!isVisible) return null

	return (
		<>
			{
				isVisible &&
                <div style={{ position: 'fixed', top: '5%', right: 20, width: '30%' }}>
                	<GlobalStyles />
                	<NotificationContainer>
                		<div className="flex items-center">
                			<Icon className="fas fa-check-square" />
                			<span>Email sent status: sent</span>
                		</div>
                		<CloseButton onClick={setVisible}>
                            (X)
                		</CloseButton>
                		<Tooltip>Close</Tooltip>
                	</NotificationContainer>
                </div>
			}
		</>
	)
}

export default Notification
