/* eslint-disable semi */
/* eslint-disable indent */
import React, { useEffect, useState } from 'react'
import { AdminGiftSubFooter, AdminGiftSubHeader, AdminGiftSubTitle, FooterText, LogoImage } from './styles'
import Card from './components/adminGiftSubscription/Card'
import { reverseObfuscateConstant } from '../../../../utils/Constant'
import { RP_ENV } from '../../../../configs'
import moment from 'moment'
import FilterElement from '../../../../components/filter-element'
import LoadingOverlay from '../../../../components/Loading-overlay'
import Notification from './components/adminGiftSubscription/Notification'
import SecurityModal from './components/adminGiftSubscription/SecurityModal'
interface GiftSub {
    sender: {
        name: {
            first_name: string;
            last_name: string;
        };
        email: string;
    };
    recipient: {
        name: {
            first_name: string;
            last_name: string;
        };
        email: string;

    };
    message: string;
    created_at: string;
    start: string;
    transaction: {
        status: string;
    }
    _id: string;
    updated_at: string;
    status: string;
    activated_at: string;
    hasButton: boolean;
}

const AdminGiftSubscription: React.FC = () => {
    const [giftSubs, setGiftSubs] = useState<GiftSub[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [statusQuery, setStatusQuery] = useState<string>('All');
    const [isLoading, setIsLoading] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [filteredGiftSubs, setFilteredGiftSubs] = useState(giftSubs);
    const [hasError, setHasError] = useState(false);
    const [showSecurityModal, setShowSecurityModal] = useState(true);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const objUser = RP_ENV.ADMIN_PAGE_SECURITY_USER
    const objPw = RP_ENV.ADMIN_PAGE_SECURITY_PW
    const reverseUsername = reverseObfuscateConstant(objUser)
    const reversePassword = reverseObfuscateConstant(objPw)

    useEffect(() => {
        if (giftSubs?.length > 0 && isLoading) {
            setIsLoading(false)
        }
    }, [giftSubs?.length])

    const fetchGiftSubList = async (statusQuery: string) => {
        const url = `${reverseObfuscateConstant(RP_ENV.API_URL_V2)}/gift-subscriptions/?status=${statusQuery}`;

        setIsLoading(true);

        const headers = {
            'Content-Type': 'application/json',
            'x-api-key': RP_ENV.API_KEY,
        };

        try {
            const response = await fetch(url, { method: 'GET', headers });

            if (!response.ok) {
                const errorMessage = await response.text();
                throw new Error(`Failed to fetch gift subscriptions: ${errorMessage}`);
            }

            const { data } = await response.json();
            setGiftSubs(data);
            return data;

        } catch (error) {
            console.error('Error fetching gift subscription list:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleResendInvite = async (giftSubId: string) => {
        setIsLoading(true)

        const url = `${reverseObfuscateConstant(RP_ENV.API_URL_V2)}/gift-subscriptions/${giftSubId}/resend`;

        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': RP_ENV.API_KEY,
            },
        });

        setIsLoading(false)


        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const responseData = await response.json();

        if (responseData) {
            setIsVisible(true)
            setTimeout(() => {
                setIsVisible(false)
            }, 3000);
        }

        return responseData;

    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await fetchGiftSubList(statusQuery);
                setGiftSubs(result)
                setFilteredGiftSubs(result)
            } catch (error) {
                console.error('Error fetching subscriptions:', error);
            }
        };

        fetchData();
    }, [statusQuery]);

    useEffect(() => {
        const expirationDate = localStorage.getItem('credentials_expiration');

        if (expirationDate) {
            const parsedExpirationDate = new Date(expirationDate);
            if (new Date() < parsedExpirationDate) {
                setShowSecurityModal(false);
            } else {
                localStorage.removeItem('credentials');
                localStorage.removeItem('credentials_expiration');
            }
        }
    }, []);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;

        setSearchQuery(value);
        const filtered = giftSubs.filter(data => {
            const sFullName = `${data?.sender?.name?.first_name} ${data?.sender?.name?.last_name}`.toLowerCase();
            const rFullName = `${data?.recipient?.name?.first_name} ${data?.recipient?.name?.last_name}`.toLowerCase();
            const sEmail = data?.sender?.email.toLowerCase();
            const rEmail = data?.recipient?.email.toLowerCase();

            return (
                sFullName?.includes(value?.toLowerCase()) ||
                rFullName?.includes(value?.toLowerCase()) ||
                sEmail?.includes(value?.toLowerCase()) ||
                rEmail?.includes(value?.toLowerCase())
            );
        });

        setHasError(filtered.length === 0);

        setFilteredGiftSubs(filtered);
    };

    const handleStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setStatusQuery(value)
        fetchGiftSubList(value)
        setSearchQuery('')
        setHasError(false)
    };

    const handleResetStates = () => {
        setSearchQuery('')
        setStatusQuery('All')
        setHasError(false)
    };

    const handleLogin = () => {
        const INVALID_CREDENTIALS_MESSAGE = 'Invalid username or password';

        if (!username || !password) {
            alert('Username and password cannot be empty');
            return;
        }
        if (username === reverseUsername && password === reversePassword) {
            const credentials = `${username}:${password}`;
            const obfuscatedCredentials = btoa(credentials);
            const expirationDate = new Date();
            expirationDate.setMonth(expirationDate.getMonth() + 1);

            localStorage.setItem('credentials', obfuscatedCredentials);
            localStorage.setItem('credentials_expiration', expirationDate.toISOString());

            setShowSecurityModal(false);
        } else {
            alert(INVALID_CREDENTIALS_MESSAGE);
        }
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <AdminGiftSubHeader>
                <LogoImage src='/img/logo-mark-white.png' alt='' />
            </AdminGiftSubHeader>

            <AdminGiftSubTitle>Gift Subscription</AdminGiftSubTitle>

            <FilterElement
                searchQuery={searchQuery}
                searchPlaceholder="Sender / Recipient email"
                options={['All', 'Redeemed', 'Not Redeemed']}
                selected={statusQuery}
                onSearchChange={handleSearchChange}
                filterByStatus={handleStatusChange}
                onReset={handleResetStates}
            />

            {hasError && <p style={{ color: 'red', textAlign: 'center' }}>No gift subscription found base on the keyword!</p>}

            <div style={{ flex: 1, justifyContent: 'center', alignSelf: 'center' }}>
                {
                    filteredGiftSubs?.map((data, key) => {
                        const sFirstName = data?.sender?.name?.first_name
                        const sLastName = data?.sender?.name?.last_name
                        const sEmail = data?.sender?.email
                        const rFirstName = data?.recipient?.name?.first_name
                        const rLastName = data?.recipient?.name?.last_name
                        const rEmail = data?.recipient?.email
                        const message = data?.message
                        const createdAt = moment(data?.created_at)?.format('DD MMM YYYY')
                        const sendDate = moment(data?.start)?.format('DD MMM YYYY')
                        const status = data?.status
                        const giftSubId = data?._id
                        const activatedAt = data?.activated_at && moment(data?.activated_at)?.format('DD MMM YYYY')

                        return (
                            <div key={key} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <Card
                                    sFirstName={sFirstName}
                                    sLastName={sLastName}
                                    sEmail={sEmail}
                                    rFirstName={rFirstName}
                                    rLastName={rLastName}
                                    rEmail={rEmail}
                                    message={message}
                                    created={createdAt}
                                    sendDate={sendDate}
                                    status={status}
                                    activatedAt={activatedAt}
                                    hasButton={!activatedAt}
                                    onResend={() => handleResendInvite(giftSubId)}
                                />
                            </div>
                        )
                    })
                }
            </div>

            <AdminGiftSubFooter>
                <FooterText>Internal Use Only</FooterText>
                <FooterText>Copyright © 2025 The Wine Advocate Inc</FooterText>
            </AdminGiftSubFooter>
            {isLoading && (<LoadingOverlay />)}
            {showSecurityModal && <SecurityModal
                userName={username}
                passWord={password}
                onChangeUsername={(e) => setUsername(e.target.value)}
                onChangePassword={(e) => setPassword(e.target.value)}
                onLogin={handleLogin}
                onCancel={() => { alert('Unauthorized access') }}
            />}
            {<Notification isVisible={isVisible} setVisible={() => setIsVisible(false)} />}
        </div >
    )
}

export default AdminGiftSubscription
