import React, { ReactElement, useState } from 'react'
import {
	FilterContainer,
	FilterWrapper,
	SearchContainer,
	SearchLabel,
	SearchInputWrapper,
	SearchIcon,
	SearchInput,
	Dropdown,
	ResetButton
} from './styles'
interface FilterElementProps {
    searchPlaceholder: string;
    options: string[];
    onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    filterByStatus: (event: any) => void;
    onReset: () => void;
    searchQuery: string;
    selected: string;
}

function FilterElement({
	searchPlaceholder = 'Search...',
	options,
	onSearchChange,
	filterByStatus,
	onReset,
	searchQuery,
	selected,
}: FilterElementProps): ReactElement {
	return (
		<FilterContainer>
			<FilterWrapper>
				{/* Search Input */}
				<SearchContainer>
					<SearchLabel>Search:</SearchLabel>
					<SearchInputWrapper>
						<SearchIcon className="mdicon mdicon-search" />
						<SearchInput
							type="text"
							value={searchQuery}
							onChange={onSearchChange}
							placeholder={searchPlaceholder}
						/>
					</SearchInputWrapper>
				</SearchContainer>

				{/* Dropdown Selection */}
				<Dropdown value={selected} onChange={filterByStatus}>
					{options?.map((option) => (
						<option key={option} value={option}>
							{option}
						</option>
					))}
				</Dropdown>
			</FilterWrapper>

			{/* Reset Button */}
			<ResetButton onClick={onReset}>Reset filters</ResetButton>
		</FilterContainer>
	)
}

export default FilterElement
