/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, ReactElement, SetStateAction, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

// Utils
import { IsUserAuthenticated, TranslationsUtil } from '../../../../utils'

import Wine from '../../../../structure/classes/Wine'
import TastingNotesPaginator from './TastingNotesPaginator'

// i18n
// import TastingNote from '../../../../structure/classes/TastingNote'
import PrintButtons from './print-button'
import englishTranslation from './translations/en.json'

// Components
// import ProducerNotes from './ProducerNotes'
import { ProducerNoteContainer } from './styles'
import moment from 'moment'
import { APP_CONFIG, STATIC_MESSAGES } from '../../../../configs'
import { isFreeUser } from '../../../../utils/AuthenticationUtil'
import { Users as UsersFromStore } from '../../../../app/slices/Users'
import { useAppSelector } from '../../../../app/hooks'
import SubscriptionInvite from '../subscription-invite'
import { isMobile } from 'react-device-detect'

const english: LanguageDefinitions = englishTranslation
const translationUtil = new TranslationsUtil(english)

type TastingNotesPropsType = {
	wine: Wine;
	data: any
	activePage: number;
	producerNotes: any[];
	setActivePage: Dispatch<SetStateAction<number>>;
	winePrice: {
		high: number;
		low: number;
	};
}

// const averagePrice = (low: number, high: number): number => {
// 	return Math.floor((low + high) / 2)
// }

function TastingNotes(props: TastingNotesPropsType): ReactElement {
	const { wine, data, activePage, setActivePage, producerNotes } = props
	const translate = translationUtil.getTranslator()
	
	const [tastingNotes, setTastingNotes] = useState<TastingNotesV2[]>([])
	const { userData } = useAppSelector(UsersFromStore)

	useEffect(() => {
		if (data) {
			setTastingNotes(data)
		}
	}, [data])

	const buttonLookUp = [
		{
			type: 'single',
			btnValue: 'Print Note',
		},
		{
			type: '2x2',
			btnValue: 'Print 2 x 2',
		},
		{
			type: '3x1',
			btnValue: 'Print 3 x 1',
		},
	]

	const renderBtnPrint = (lengthOfNotes: number) => {
		return buttonLookUp.slice(0, lengthOfNotes)
	}

	const generateTastingNoteContent = (
		tastingNote: TastingNotesV2[],
		activeTastingNote: TastingNotesV2
	): ReactElement => {
		const drinkDateDisplay = () => {
			if (
				activeTastingNote &&
				activeTastingNote.drink_date &&
				(activeTastingNote.drink_date.high || activeTastingNote.drink_date.low)
			) {
				return activeTastingNote.drink_date.high
					? `${activeTastingNote.drink_date.low} - ${activeTastingNote.drink_date.high}`
					: `${activeTastingNote.drink_date.low}`
			}

			return 'N/A'
		}

		if(activeTastingNote?.rating?.display !== '100' && isFreeUser(userData)){
			return <SubscriptionInvite inviteMessage={STATIC_MESSAGES.PAYWALL_TITLE} mainMessage={STATIC_MESSAGES.WINE_PAYWALL_DESC} />
		}

		return (
			<div className="entry-content typography-copy wine-page-layout" itemProp="articleBody" style={{padding: isMobile ? 24 : 32}}>
				<div>
					<div>
						<div className="mnmd-review__top">
							<div className="mnmd-review__product media" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
								<div className="tasting-notes-text" style={{ color: '#a08436' }}>{activeTastingNote && activeTastingNote.publication && activeTastingNote.publication.name ? activeTastingNote.publication.name : 'WA Tasting Notes'}</div>
								{activeTastingNote && activeTastingNote.rating && activeTastingNote.rating.display ?
									<div style={{ fontSize: 24 }}>
										{activeTastingNote.publication._id === APP_CONFIG.TWA_PUBLICATION ? <span style={{ fontWeight: 600 }}>RP&nbsp;</span> : <></>}
										{activeTastingNote?.rating?.display}
									</div>
									: <div style={{ fontSize: 24 }}>
										{<span style={{ fontWeight: 600 }}>RP&nbsp; </span>}
										<span>N/A</span>
									</div>
								}
							</div>
						</div>
						<div className="mnmd-review__summary">
							<div className="row">
								<div className="col-12 col-sm-4">
									{activeTastingNote && activeTastingNote.author && activeTastingNote.author.id && activeTastingNote.author.name ? (
										<dl>
											<dt>{translate('Reviewed by')}:</dt>
											<dd style={{ marginTop: '5px' }}>
												<Link to={`/author/${activeTastingNote.author.id}`}>
													{activeTastingNote.author.name}
												</Link>
											</dd>
										</dl>
									) : (
										<>N/A</>
									)}
								</div>
								<div className="col-12 col-sm-4">
									<dl>
										<dl>
											<dt>{translate('Release Price')}:</dt>
											<div style={{ marginTop: '5px' }}>
												{activeTastingNote && activeTastingNote.price && activeTastingNote.price.low ? (
													<dd>
														<b>${activeTastingNote.price.low}</b>
													</dd>
												) : (
													<>NA</>
												)}
											</div>
										</dl>

									</dl>
								</div>
								<div className="col-12 col-sm-4">
									<dl>
										<dt>{translate('Drink Date')}:</dt>
										<dd style={{ marginTop: '5px' }}>{drinkDateDisplay()}</dd>
									</dl>
								</div>
							</div>
							{activeTastingNote && activeTastingNote.content ? (
								<>
									<p style={{ whiteSpace:'pre-line' }}>{activeTastingNote.content}</p>
									{producerNotes?.length > 0 ? producerNotes.map((content: any, index: number) => (content.content && content.assignment._id === activeTastingNote.assignment._id) ? <ProducerNoteContainer key={index} dangerouslySetInnerHTML={{ __html: content?.content.replace(/\n/g, '<br>') }} /> : null) : null}
								</>
							) : (
								<></>
							)}
						</div>
						<p style={{ marginTop: '1rem' }}>
							{ activeTastingNote?.published_at && <>
								<b>Published: </b>{moment(activeTastingNote?.published_at).format('MMM DD, YYYY')} </> 
							}
						</p>
						<hr style={{ marginTop: '30px', marginBottom: '20px' }} />
						{!isFreeUser(userData) && IsUserAuthenticated() && 
						<div className="text-center">
							<form>
								{tastingNotes?.length && renderBtnPrint(3).map((ele: any, key: number) => {
									return (
										<PrintButtons
											item={ele}
											key={key}
											wine={wine}
											tastingNote={tastingNote}
											drinkDate={drinkDateDisplay()}
											subscription={userData}
										/>
									)
								})}
							</form>
						</div>
						}
					</div>
				</div>
			</div>
		)
	}

	/**
	 * Set the value for hte active page
	 * @param page 
	 */
	const changeActivePage = (page: number) => setActivePage(page)

	const paginationSection = (length: number) => (length) ? (<TastingNotesPaginator tastingNoteCount={tastingNotes?.length} activeNumber={activePage} actionEvent={changeActivePage} />) : null
	return (
		<>
			<div className="single-content">
				{tastingNotes[activePage - 1]
					? generateTastingNoteContent(tastingNotes, tastingNotes[activePage - 1])
					: ''}
			</div>
			{paginationSection(tastingNotes?.length)}
		</>
	)
}

export default TastingNotes