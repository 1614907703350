/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { getTypes } from '../../../../../utils/SearchUtil'
import { SetActiveAdvanceFilters, SearchFilters as SearchFiltersFromStore } from '../../../../../app/slices/SearchFilters'
import { useAppSelector } from '../../../../../app/hooks'

// Styles
import { PublicationDateFormContainer } from '../styles'
import { ActionButton } from '../../subscriptions/styles'


type Props = {
	publicationDateData?:{
		dateFrom: number;
		dateTo: number;
	}
	type: string
	clickApplyHandler: (data: any) => void,
	dateRangeData?: any
}

function PublicationDateFilter({clickApplyHandler, publicationDateData, type, dateRangeData}: Props): ReactElement {

	const defaultFromDate = '1992-01-01'
	const defaultFormat = 'YYYY-MM-DD'
	const defaultToDate = moment().format(defaultFormat)

	const [isApplyDisabled, setIsApplyDisabled] = useState(false)
	const [isChangeFromDate, setIsChangFromDate] = useState(true)
	const [fromDateInitialValue, setFromDateInitialValue] = useState('1989-01-01')
	const [fromDate, setFromDate] = useState(defaultFromDate)
	const [toDate, setToDate] = useState(defaultToDate)
	const [errorMessage, setErrorMessage] = useState<any>({
		message: '',
		isError: false
	})
	const { pathname } = useLocation()
	const searchType = getTypes(pathname)
	const dispatch = useDispatch()
	const {AdvanceFilter: advanceFilter} = useAppSelector(SearchFiltersFromStore)

	useEffect(() => {
		if(publicationDateData){
			if(publicationDateData.dateFrom !== undefined && publicationDateData.dateTo !== undefined){
				let fromDate = publicationDateData.dateFrom.toString()
				let toDate = publicationDateData.dateTo.toString()
	
				const datePattern = /^\d{4}-\d{2}-\d{2}$/
				if (!datePattern.test(fromDate) && !datePattern.test(toDate)) {
					fromDate = new Date(publicationDateData.dateFrom).toISOString().slice(0, 10)
					toDate = new Date(publicationDateData.dateTo).toISOString().slice(0, 10)
				}
				
				if(publicationDateData.dateFrom !== 0 && publicationDateData.dateTo !== 0){
					setFromDate(fromDate)
					setToDate(toDate)
					if(isChangeFromDate){
						setFromDateInitialValue(toDate)
					}
				} else {
					setFromDate(defaultFromDate)
					setToDate(defaultToDate)
				}
			}
		}
	},[publicationDateData])

	useEffect(() => {
		if(dateRangeData){
			setToDate(moment(dateRangeData.max).format('YYYY-MM-DD'))
			setFromDate(moment(dateRangeData.min).format('YYYY-MM-DD'))
		}
	}, [dateRangeData])
	

	useEffect(() => {
		if (fromDate || toDate) {
			setIsApplyDisabled(fromDate === toDate)
		}
		if (pathname === '/advanced-search/wine' || pathname === '/advanced-search/article') {
			if (advanceFilter && advanceFilter.date_last_tasted) {
				const fromDateTimestamp = new Date(fromDate).getTime()
				const toDateTimestamp = new Date(toDate).getTime()
	
				dispatch(SetActiveAdvanceFilters({
					advanceFilter: {
						date_last_tasted: [fromDateTimestamp, toDateTimestamp]
					}
				}))
			}
		}
		setIsChangFromDate(false)
	}, [fromDate, toDate])

	const handleDatePicker = (date: Date | null, type: 'from' | 'to', pageType: string) => {
		if (date) {
			const formattedDate = moment(date).format(defaultFormat)

			if (type === 'from') {
				setFromDate(formattedDate)
				setErrorMessage({
					isError: false
				})
				if(pageType !== 'normal-search') {
					const prevToDate = advanceFilter && advanceFilter.date_last_tasted ? advanceFilter.date_last_tasted[1] : new Date().getTime()
					dispatch(SetActiveAdvanceFilters({
						advanceFilter: { ['date_last_tasted']: [new Date(formattedDate).getTime(), prevToDate]}
					}))
				}

				return
			}

			if (pageType !== 'normal-search') {
				const prevFromDate = advanceFilter && advanceFilter.date_last_tasted ? advanceFilter.date_last_tasted[0] : new Date().getTime()

				dispatch(SetActiveAdvanceFilters({
					advanceFilter: { ['date_last_tasted']: [prevFromDate, new Date(formattedDate).getTime()] }
				}))
			}

			setToDate(formattedDate)
			setErrorMessage({
				isError: false
			})
		}
	}

	const applyHandler = () => {
		if(fromDate === '') {
			setErrorMessage({
				message: 'Set Publication Date From',
				isError: true
			})
			return
		}
		if(toDate === '') {
			setErrorMessage({
				message: 'Set Publication Date To',
				isError: true
			})
			return
		}
		if(fromDate > toDate) {
			setErrorMessage({
				message: 'Invalid Publication Date',
				isError: true
			})
		} else {
			setErrorMessage({
				message: '',
				isError: false
			})
			clickApplyHandler({fromDate, toDate})
			
			const offCanvas: HTMLElement | null = document.querySelector('#mnmd-filter')
			const backdrop: HTMLElement | null = document.querySelector('#backdrop-filter')
			if (offCanvas !== null && offCanvas.classList.contains('is-active')) {
				offCanvas.classList.remove('is-active', 'ps-container', 'ps-theme-default')
			}

			if(backdrop){
				backdrop.remove()
			}
		}
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		})
	}

	const changeDate = (date: Date, type: 'To' | 'From', dateFormat: 'year' | 'month') => {
		let origDate = fromDate

		if(type === 'To')
			origDate = toDate

		const dates = origDate.split('-')
		const origYear = dates[0]
		const origMonth = dates[1]
		const origDay = dates[2]

		let format = moment(new Date(date)).format('MM')

		if(dateFormat === 'year')
			format = moment(new Date(date)).format('YYYY')

		let finalDate = `${origYear}-${format}-${origDay}`

		if(dateFormat === 'year')
			finalDate = `${format}-${origMonth}-${origDay}`

		if(moment(finalDate).isBefore(moment('1989-01-01')) || moment(finalDate).isAfter(moment()))
			return

		if(type === 'From' && moment(finalDate).isAfter(moment(toDate)))
			return

		if(type === 'To' && moment(finalDate).isBefore(moment(fromDate)))
			return

		if(type === 'To')
			return setToDate(finalDate)

		return setFromDate(finalDate)
	}

	return (
		<div>
			{type === 'normal-search' ?
				<PublicationDateFormContainer>
					{searchType !== 'article' &&
					<>
						<p className='sub-title'>Based on the latest tasting note</p>
					</>}
					<div className='from-field'>
						<span>From</span>
						<DatePicker className='component-design'
							onChange={(date) => handleDatePicker(date, 'from', type)}
							showMonthDropdown
							showYearDropdown
							selected={fromDate ? moment(fromDate, defaultFormat).toDate() : moment(defaultFromDate, defaultFormat).toDate()}
							dropdownMode="select"
							dateFormat="MM/dd/yyyy"
							maxDate={toDate ? moment(toDate, defaultFormat).toDate() : moment(defaultToDate, defaultFormat).toDate()}
							minDate={moment(fromDateInitialValue, defaultFormat).toDate()}
							placeholderText="Select a date"
							onMonthChange={(month) => changeDate(month, 'From', 'month')}
							onYearChange={(year) => changeDate(year, 'From', 'year')}
						/>
					</div>
					<div className='to-field'> 
						<span>To</span>
						<DatePicker className='component-design'
							onChange={(date) => handleDatePicker(date, 'to', type)}
							showMonthDropdown
							showYearDropdown
							selected={toDate ? moment(toDate, defaultFormat).toDate() : moment(defaultToDate, defaultFormat).toDate()}
							dropdownMode="select"
							dateFormat="MM/dd/yyyy"
							maxDate={new Date()}
							minDate={fromDate ? moment(fromDate, defaultFormat).toDate() : null}
							placeholderText="Select a date"
							onMonthChange={(month) => changeDate(month, 'To', 'month')}
							onYearChange={(year) => changeDate(year, 'To', 'year')}
						/>
					</div>
					<div className='errorContainer'>
						{errorMessage.isError ? errorMessage.message : ''}
					</div>
					<div className='applyBTN'>
						<ActionButton style={{width: '100%' }} onClick={() => applyHandler()} disabled={isApplyDisabled}>Apply</ActionButton>
					</div>
				</PublicationDateFormContainer>
				:
				<div style={{paddingLeft: '20px'}}>
					<div style={{ paddingBottom: '10px', paddingTop: '5px' }}>
						<span style={{ fontWeight: 'bold' }}>Publication Date</span>
					</div>
					<PublicationDateFormContainer>
						<span>From</span>
						<div className='from-field'>
							<DatePicker className='component-design'
								onChange={(date) => handleDatePicker(date, 'from', type)}
								showMonthDropdown
								showYearDropdown
								selected={fromDate ? moment(fromDate, defaultFormat).toDate() : moment(defaultFromDate, defaultFormat).toDate()}
								dropdownMode="select"
								dateFormat="MM/dd/yyyy"
								maxDate={toDate ? moment(toDate, defaultFormat).toDate() : moment(defaultToDate, defaultFormat).toDate()}
								minDate={moment(fromDateInitialValue, defaultFormat).toDate()}
								placeholderText="Select a date"
								onMonthChange={(month) => {
									changeDate(month, 'From', 'month')
									handleDatePicker(month, 'from', type)
								}}
								onYearChange={(year) => {
									changeDate(year, 'From', 'year')
									handleDatePicker(year, 'from', type)
								}}
							/>
						</div>
						<span>To</span>
						<div className='to-field'>
							<DatePicker className='component-design'
								onChange={(date) => handleDatePicker(date, 'to', type)}
								showMonthDropdown
								showYearDropdown
								selected={toDate ? moment(toDate, defaultFormat).toDate() : moment(defaultToDate, defaultFormat).toDate()}
								dropdownMode="select"
								dateFormat="MM/dd/yyyy"
								maxDate={new Date()}
								minDate={fromDate ? moment(fromDate, defaultFormat).toDate() : null}
								placeholderText="Select a date"
								onMonthChange={(month) => {
									changeDate(month, 'To', 'month')
									handleDatePicker(month, 'to', type)
								}}
								onYearChange={(year) => {
									changeDate(year, 'To', 'year')
									handleDatePicker(year, 'to', type)
								}}
							/>
						</div>
						<div className='errorContainer'>
							{errorMessage.isError ? errorMessage.message : ''}
						</div>
					</PublicationDateFormContainer>
				</div>
			}			
		</div>
	)
}

export default PublicationDateFilter