/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment'
import React, { ReactElement, ReactNode, useEffect, useState } from 'react'
import { SwitchToggleContainer, InputCheckbox, Slider, CardBody, CardContainer, ContentDetail, AddOnsDetails, AutoRenewContainer, ChangePlanText } from './styles'
import { useAppSelector } from '../../../../app/hooks'
import LoadingOverlay from '../../../../components/Loading-overlay'
import NoticeModal from '../../../../components/notice-modal'
import { useGetCompanyGroupsQuery, useGetSubscriptionDetailsQuery, useGetUserDetailsByIdQuery, useUpdateAutoRenewalMutation } from '../../../../app/services'
import { isMobile } from 'react-device-detect'
import { Users as UsersFromStore } from '../../../../app/slices/Users'
import './style.css'
import { getCSURFToken } from '../../../../utils/CSRFToken'
import { PLANS } from '../../../../configs'
import { AddOnsButton } from '../add-ons/styles'
import { useHistory } from 'react-router-dom'
import ErrorModal from '../../../../components/error-modal'
import { isSubscriptionExpired } from '../../../../app/services/constant'

function SubscriptionDetail(): ReactElement {
	const [mySubscription, setMySubcription] = useState<any>({
		addOns: [],
		end: '',
		planId: '',
		price: 0,
		status: '',
		_id: null,
		noSubscriptions: false,
		planLabel: ''
	})

	const [state, setState] = useState({
		isLoading: true,
		isToggleRenewal: false,
		renewalModalMsg: '',
		renewalStatus: false,
		error: {
			isShow: false,
			message: ''
		}
	})
	const [subscriptionError, setSubscriptionError] = useState({
		isError: false,
		message: ''
	})

	const history = useHistory()
	const { userData } = useAppSelector(UsersFromStore)
	const isSubsExpired = isSubscriptionExpired(userData?.subscription?.end)

	//API 
	const { data, isLoading: fetchingSubEntryLoading, isError: fetchingSubEntryisError } = useGetSubscriptionDetailsQuery(userData?.subscription?._id, { skip: !userData?.subscription?._id })
	const [executeUpdateAutoRenewalMutation, { data: updateAutoRenewalData, isLoading: updateAutoRenewalIsLoading, isError: updateAutoRenewalIsError }] = useUpdateAutoRenewalMutation()
	const { data: fetchData } = useGetCompanyGroupsQuery(userData?._id, { skip: !userData?._id })
	const { data: user, refetch: userRefetch } = useGetUserDetailsByIdQuery(userData?._id, { skip: !userData?._id })

	// States
	const [renewalPlanTotalPrice, setRenewalPlanTotalPrice] = useState<number>(0)
	const [totalActiveAccounts, setTotalActiveAccounts] = useState<number>(0)
	const [planLabel, setPlanLabel] = useState<string>('')
	const [planPrice, setplanPrice] = useState<string>('')

	// Constants
	const isCurrentUserCommercial = mySubscription?.planId.includes('commercial')
	const isCurrentUserFree = userData?.orbit_roles[0].includes('customer-free') ? true : false
	const isCurrentUserHaveActiveSubscription = userData?.subscription ? true : false
	const isCurrentUserTrialSubscription = userData?.subscription?.trial ? true : false
	const isCurrentSubscriptionCanceled = userData?.subscription?.status === 'Canceled' ? true : false
	const isUserCommercialWithAddons = userData?.subscription?.addOns[0]?.quantity !== 0 ? true : false
	
	const changePLanId = userData?.change_plan
	const checkPlanId = PLANS.find(plan => plan.planId === userData?.subscription?.planId)
	const planToBeRenewed = checkPlanId?.label || ''
	const subscriptionSubsequentAddons = mySubscription?.addOns[0]?.subsequent
	const isChangePlanPresent = userData?.change_plan ?  true : false
	const changePlanLabel = PLANS.find(plan => plan.planId === userData?.change_plan)?.label
	const changePlanNumericPrice = PLANS.find(plan => plan.planId === userData?.change_plan)?.numericPrice
	const userAddOns = userData?.subscription?.addOns[0]?.quantity
	const canChangePlan = !isChangePlanPresent && !isCurrentUserTrialSubscription && (isCurrentUserHaveActiveSubscription || userData?.subscription?.trial !== undefined) && !isSubsExpired ? true : false


	const handleFilterChangePlanId = () => {
		const filteredPlanId = PLANS.filter(plan => plan.planId === changePLanId)
		setPlanLabel(filteredPlanId[0]?.label)
		setplanPrice(filteredPlanId[0]?.price)
	}

	useEffect(() => {
		if (data) {
			if (data.success) {
				const planLabel = PLANS.find(plan => plan.planId === data?.data?.planId)
				if (planLabel) {
					setMySubcription({ ...data.data, noSubcriptions: false, planLabel: planLabel.label })
				}
				const planPrice = data.data.price
				const addOnsQuantity = (subscriptionSubsequentAddons !== undefined && subscriptionSubsequentAddons === 0) ? subscriptionSubsequentAddons : data.data?.addOns[0]?.quantity || 0
				const addOnsDefaultPrice = 129
				const addOnsTotalPrice = addOnsQuantity * addOnsDefaultPrice

				setplanPrice(planPrice)
				setRenewalPlanTotalPrice(!isCurrentUserCommercial ? planPrice : addOnsTotalPrice + planPrice)

			} else {
				setMySubcription({
					addOns: [],
					end: '',
					planId: '',
					price: 0,
					status: '',
					_id: null,
					noSubscriptions: true,
					planLabel: ''
				})
			}
			setState(state => ({ ...state, isLoading: false }))
		}

		if (fetchingSubEntryisError) {
			console.log('Something went wrong. Please try again.')
			setState(state => ({ ...state, isLoading: false }))
		}

		if (userData) {
			if (userData?.auto_renewal_status) {
				setState(state => ({ ...state, renewalStatus: userData?.auto_renewal_status === 'ON' ? false : true }))
			}
			setState(state => ({ ...state, isLoading: false }))
		}
	}, [data, fetchingSubEntryisError, userData, renewalPlanTotalPrice])

	useEffect(() => {
		if (fetchData) {
			if (fetchData.success) {
				if (fetchData.data.length) {
					let totalActiveAccounts = 0
					fetchData.data.forEach((data: any) => {
						if (data.profile && data.profile.account_status === 'active' && data.subscription.manager.invite_status !== 'Pending') {
							totalActiveAccounts++
						}
					})
					setTotalActiveAccounts(totalActiveAccounts)
				}
			}
		}
		handleFilterChangePlanId()
	}, [fetchData])

	const openErrorModal = (message: string) => {
		setState((state: any) => ({ ...state, error: { isShow: true, message: message } }))
	}

	const handleOpenNoticeModal = () => {
		let message = `Are you sure you want to turn off auto-renewal? Your subscription will expire on ${moment(mySubscription.end).format('MMM DD, YYYY')}.`

		if (state.renewalStatus)
			message = `Are you sure you want to turn on auto-renewal?  Your subscription will be renewed on ${moment(mySubscription.end).format('MMM DD, YYYY')}.`

		setState(state => ({ ...state, isToggleRenewal: true, renewalModalMsg: message }))
	}

	const handleChangeRenewal = async (isConfirm: boolean) => {
		if (isConfirm) {
			setState(state => ({ ...state, isToggleRenewal: false, isLoading: true }))
			const autoRenewalStatus = state.renewalStatus === true ? 'ON' : 'OFF'
			const payload = {
				userId: userData?._id,
				status: autoRenewalStatus
			}

			const csrf_token = await getCSURFToken()
			await executeUpdateAutoRenewalMutation({ params: payload, token: csrf_token })
			userRefetch()
		} else {
			setState(state => ({ ...state, isToggleRenewal: false }))
		}
	}

	useEffect(() => {
		if (updateAutoRenewalData) {
			if (updateAutoRenewalData.success) {
				setState(prevState => ({
					...prevState,
					isLoading: false,
					renewalStatus: !prevState.renewalStatus
				}))
			} else {
				openErrorModal(updateAutoRenewalData.message)
			}
		}

		if (updateAutoRenewalIsError) {
			setState(state => ({ ...state, isLoading: false }))
			openErrorModal('Something went wrong. Please try again.')
		}
	}, [updateAutoRenewalData, updateAutoRenewalIsError])

	const addOnsQuantity = () => {
		// return userData?.subscription?.addOns[0]?.quantity ? userData?.subscription?.addOns[0]?.quantity : 0
		return mySubscription?.addOns[0]?.quantity ? mySubscription?.addOns[0]?.quantity : 0
	}

	function convertedAddoOns(quantity: number, totalRecords: number) {
		if (isNaN(totalRecords - quantity) || quantity - totalRecords === -1) {
			return 0
		} else {
			return quantity - totalRecords
		}
	}

	const ContentDetails = ({ children, title }: { children: ReactNode; title: string }): ReactElement => {
		return <ContentDetail><span className='title'>{title}</span> {children}</ContentDetail>
	}

	const PlansAndPrice = ({
		planToBeRenewed,
		subscriptionSubsequentAddons,
		renewalPlanTotalPrice,
	}: {
		planToBeRenewed: string;
		subscriptionSubsequentAddons: number;
		renewalPlanTotalPrice: number;
	}): ReactElement => {
		return (
			<div>
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<strong>Plan to be renewed:</strong>
					<strong>Renewal Price</strong>
				</div>
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<p>
						{planToBeRenewed}
						{
							isCurrentUserCommercial && addOnsQuantity() !== 0 && <span>
								{
									subscriptionSubsequentAddons === 0
										? subscriptionSubsequentAddons !== 0 && ` + ${subscriptionSubsequentAddons} add ons`

										: userAddOns !== 0 && ` + ${userAddOns} add ons`
								}
							</span>
						}
					</p>
					<p>USD ${renewalPlanTotalPrice.toFixed(2)}</p>
				</div>
			</div>
		)
	}

	const handleSubscriptionChecker = (type: string) => {
		if (isSubsExpired) {
			setSubscriptionError({
				isError: true,
				message: 'You are not authorized to buy additional seats.'
			})
			return
		} else {
			const url = `/buy-addons/${userData?._id}&type=${type}`
			window.location.href = url
		}
	}

	const AddOnDetails = ({ isMobile, quantity, totalCost, availableAddons, onReduce, onAdd, onEdit }: any) => (
		<AddOnsDetails isMobile={isMobile}>
			<p>Additional Seats: <span>{quantity}</span> (${totalCost})</p>
			<span>
				<span>{availableAddons} Available &nbsp;</span>
				{onReduce && onAdd ? (
					<>
						{availableAddons !== 0 && <a style={{ cursor: 'pointer', fontWeight: 'bold' }} onClick={onReduce}>Reduce Addons,</a>}
						<span>&nbsp;</span>
						<a style={{ cursor: 'pointer', fontWeight: 'bold' }} onClick={onAdd}>Add More</a>
					</>
				) : (
					<a style={{ cursor: 'pointer' }} onClick={onEdit}>EDIT</a>
				)}
			</span>
		</AddOnsDetails>
	)

	const AddOnContainer = () => {
		const quantity = addOnsQuantity()
		const totalCost = quantity * 129
		const availableAddons = convertedAddoOns(mySubscription?.addOns[0]?.quantity, totalActiveAccounts)

		const canManageAddons = isUserCommercialWithAddons || isCurrentUserCommercial

		return (
			canManageAddons && !isCurrentUserFree && isCurrentUserCommercial ? (
				<AddOnDetails
					isMobile={isMobile}
					quantity={quantity}
					totalCost={totalCost}
					availableAddons={availableAddons}
					onReduce={() => handleSubscriptionChecker('reduce')}
					onAdd={() => handleSubscriptionChecker('add')}
					onEdit={() => handleSubscriptionChecker('')}
				/>
			) : null
		)
	}

	return (
		
		<>
		
			{state.isLoading && <LoadingOverlay />}
			{fetchingSubEntryLoading && <LoadingOverlay />}
			{updateAutoRenewalIsLoading && <LoadingOverlay />}
			<div className='subcription-details-container'>
				<CardContainer isMobile={isMobile}>
					<CardBody isMobile={isMobile}>
						<p className='head-body-subsription'>Your Subscription Details</p>
						<div>
							<ContentDetails title='Plan:'>{mySubscription.planLabel ? mySubscription.planLabel : 'You are currently not subscribed to any kind of subscription.'} {isCurrentUserTrialSubscription && <span style={{ fontStyle: 'italic' }}>( Trial )</span>}</ContentDetails>
							{/* <ContentDetails title='Status:'>{userData?.subscription?.status}</ContentDetails> */}
							{!isCurrentUserFree && <ContentDetails title='Expiration Date:'>{mySubscription.end ? moment(userData?.subscription?.end).format('MMM DD, YYYY') : 'N/A'}</ContentDetails>}
							{
								userData?.subscription && (
									<AutoRenewContainer>
										<span>
											<span className='title'>Auto Renew: &nbsp;</span>
											<span className='onLabel title'>
												{isSubsExpired || isCurrentUserFree || user?.data?.auto_renewal_status === 'OFF' ? 'OFF' : 'ON'} &nbsp;
											</span>
											<SwitchToggleContainer disabled={isSubsExpired || isCurrentUserFree}>
												<InputCheckbox
													type="checkbox"
													// checked={(!isSubsExpired || !isCurrentUserFree || !(user?.data?.auto_renewal_status === 'OFF'))}
													checked={!(isSubsExpired || isCurrentUserFree || user?.data?.auto_renewal_status === 'OFF')}
													onChange={handleOpenNoticeModal}
												/>
												<Slider />
											</SwitchToggleContainer>
										</span>
										{
											canChangePlan &&
												<ChangePlanText onClick={() => history.push('/change-plan')}>CHANGE PLAN</ChangePlanText>
										}
									</AutoRenewContainer>
								)
							}

							{
								(userData?.subscription == null || isSubsExpired) ? null : (
									<p style={{ textAlign: 'left', fontSize: 14, color: '#000', marginLeft: isMobile ? 0 : 24 }}>
										{changePLanId ? (
											<span>
												Your subscription plan will be changed to <b>{planLabel}</b> for <b>{planPrice}</b> on the next renewal.
											</span>
										) : null}
										{!isMobile && <>&nbsp;</>}
										<span>
											{user?.data?.auto_renewal_status === 'ON'
												? 'Your default payment method will be charged automatically on'
												: 'Your subscription will expire on'}
											<strong> {moment(mySubscription.end).format('MMM DD, YYYY')}.</strong>
										</span>
									</p>
								)
							}

							{
								isUserCommercialWithAddons && !isCurrentUserFree &&  isCurrentUserCommercial && (
									<ContentDetails title='Add-Ons:'>
										{userData?.subscription ? 'YES' : 'NO'}
									</ContentDetails>
								)
							}

							{!isCurrentSubscriptionCanceled && !isCurrentUserFree && <AddOnContainer />}

							{!isCurrentUserFree && <ContentDetails title='Total Price:'>${renewalPlanTotalPrice?.toFixed(2)}</ContentDetails>}

							{
								!isCurrentUserFree ?
									isChangePlanPresent
										?
										<PlansAndPrice
											planToBeRenewed={changePlanLabel || ''}
											subscriptionSubsequentAddons={isChangePlanPresent ? 0 : subscriptionSubsequentAddons}
											renewalPlanTotalPrice={changePlanNumericPrice ?? 0}
										/>
										:
										<PlansAndPrice
											planToBeRenewed={planToBeRenewed}
											subscriptionSubsequentAddons={subscriptionSubsequentAddons}
											renewalPlanTotalPrice={renewalPlanTotalPrice}
										/>
									: null
							}

							{(isSubsExpired || !userData?.subscription) && (
								<div style={{ display: 'flex', justifyContent: 'center', height: 40 }}>
									<AddOnsButton onClick={() => history.push('/subscriptions')}>
										BUY SUBSCRIPTION
									</AddOnsButton>
								</div>
							)}

						</div>
					</CardBody>
				</CardContainer>
			</div>
			{!state.isToggleRenewal ? '' : <NoticeModal messageBody={state.renewalModalMsg} title={'RENEWAL'} isOpen={state.isToggleRenewal} onClose={handleChangeRenewal} isBlocked={false} />}
			{subscriptionError.isError && <ErrorModal messageBody={subscriptionError.message} isOpen={subscriptionError.isError} onClose={() => setSubscriptionError({ isError: false, message: '' })} bgColor='#73333F' closeTextColor='white' />}

		</>
	)
}

export default SubscriptionDetail
