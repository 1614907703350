import React, { ChangeEvent, ReactElement } from 'react'
import { isMobile } from 'react-device-detect'

import { MenuOptionContainer } from '../../views/content/pages/faq/common'
import { SelectOption } from '../../views/content/Common'

type Props = {
    menuItems: string[];
    selectedMenu: number;
    onMenuChange: (index: number) => void;
    className?: string;
}

type OptionProps = {
    index: number;
    handleMenuChange: (index: number) => void;
    label: string;
} 

function MenuSelections({menuItems, selectedMenu, onMenuChange, className}: Props): ReactElement {

	const Option = ({ index, handleMenuChange, label }: OptionProps): ReactElement => {
		return <SelectOption selected={(selectedMenu === index) ? true : false} onClick={() => handleMenuChange(index)} >
			{label}
		</SelectOption>
	}

	const onChangeSelectHandler = (event: ChangeEvent<HTMLSelectElement>) => {
		const { value } = event.target
		const selectedMenu = parseInt(value)
		onMenuChange(selectedMenu)
	}

	return <>
		{isMobile ?
			<select className='about-selection component-design tall-select' onChange={onChangeSelectHandler} value={selectedMenu}>
				{
					menuItems.map((option: string, index: number) => <option key={index} value={index}>
						{option}
					</option>)
				}
			</select>
			:
			<MenuOptionContainer className={className}>
				{menuItems.map((option: string, index: number) => <Option key={index} index={index} handleMenuChange={onMenuChange} label={option} />)}
			</MenuOptionContainer>
		}

	</>
}

export default MenuSelections
