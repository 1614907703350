/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect, useState } from 'react'
import { APP_CONFIG } from '../../../../../configs'
// Redux
import { useAppSelector } from '../../../../../app/hooks'
import { ClearPublicationDateFilters, SearchFilters as SearchFiltersFromStore, SetActiveAdvanceFilters, SetActiveFilters, SetFacetsFilters, SetPagination } from '../../../../../app/slices/SearchFilters'


// Styles
import { GeneratedFiltersContainer } from '../common/styles'

import { getFilterKey, getFilterOptions, getFilters, getFilterTitle, lowerCaseId } from '../../../../../utils/FilterUtils'
import SectionSeparator from '../../../sections/section-separator'

import queryString from 'query-string'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { generateActiveFilters, IsChecked, removeFilterFromQueryString } from '../../../../../utils/SearchUtil'

import TagManager from 'react-gtm-module'
import { convertObject, intersection, removePrefix } from './filterConstants'
import { ResetButton, TitleResetContainer } from '../../advanced-search/styles'
import PublicationDateFilter from '../../advanced-search/sections/PublicationDateFilter'
import { IsUserAuthenticated } from '../../../../../utils'
// import { Users as UsersFromStore } from '../../../../../app/slices/Users'
// import { isFreeUser } from '../../../../../utils/AuthenticationUtil'

const limit = APP_CONFIG.SEARCH.OFFSET_LIMIT.limit
const tastingNotesLimit = APP_CONFIG.SEARCH.ALTERNATE_OFFSET_LIMIT.limit

type PublicationDateType = {
	dateFrom: number,
	dateTo: number,
}

function FilterOptions(props: { filters: SearchFilterObject, searchType: string; wineDisplay: string; onToggleFilter: () => void, onToggleClear: () => void, rangeData: any }): ReactElement {

	const { type } = useParams<{ type: string }>()

	const { filters, searchType, wineDisplay, rangeData } = props
	// const { userData } = useAppSelector(UsersFromStore)

	const history = useHistory()
	const { search } = useLocation()
	const queryStrings = queryString.parse(search)
	const dispatch = useDispatch()
	const activeFilters = useAppSelector(SearchFiltersFromStore).ActiveFilters
	let newFilterOptions = []
	const [triggerFacetSelectionEvent, setTriggerFacetSelectionEvent] = useState(false)
	const [selectedReferences, setSelectedReferences] = useState<string[]>([])

	// const isAuthenticated = IsUserAuthenticated()
	const activeFilterData = useAppSelector(SearchFiltersFromStore)


	/**
	 * Executes the process of generating the filter queries. 
	 * 
	 * @param event  + APP_CONFIG.SEARCH.OFFSET_LIMIT
	 */

	const refreshWineDisplay = () => {
		let newLimit = limit
		if (type === APP_CONFIG.SEARCH.TYPES.WINE && wineDisplay === 'wineList') {
			newLimit = tastingNotesLimit
		}
		dispatch(SetPagination({ limit: newLimit, offset: 0 }))
		window.scrollTo({ top: 0, behavior: 'smooth' })
	}

	const toggleFilter = (event: React.ChangeEvent<HTMLInputElement>, reference: string) => {
		setTriggerFacetSelectionEvent(true)
		props.onToggleFilter()
		if (queryStrings[reference] !== undefined) {
			const newQueryString = removeFilterFromQueryString(search, event.target.id)
			if (newQueryString !== '') history.replace(`/search/${searchType}?${newQueryString}`)
			else history.replace(`/search/${searchType}`)
		}
		const newActiveFilter = generateActiveFilters(activeFilters, reference, event.target.id)
		dispatch(SetFacetsFilters({
			activeFilters: newActiveFilter,
		}))
		refreshWineDisplay()
	}

	const handleFacetAnalytics = (data: any) => {
		const facetSelections: { [key: string]: string | undefined } = {}
		Object.entries(data).forEach(([key, values]) => {
			let facetKey = key
			if (Array.isArray(values) && values.length > 0) {
				const concatenatedValues = values.map(val => val.split(':')[1]).join(', ')
				if (facetKey === 'sub_region') {
					facetKey = 'subregion'
				} else if (facetKey === 'sub_appellation') {
					facetKey = 'subappellation'
				} else if (facetKey === 'color_class') {
					facetKey = 'colorclass'
				} else if (facetKey === 'issue_title') {
					facetKey = 'issuetitle'
				}

				facetSelections[facetKey] = concatenatedValues
			}
		})

		const allFacetFields = [
			'vintage', 'reviewers', 'country', 'region', 'subregion',
			'appellation', 'subappellation', 'producer', 'varieties',
			'maturity', 'type', 'colorclass', 'dryness', 'tags',
			'certified', 'tags', 'regions', 'countries', 'author.name',
			'issuetitle'
		]

		allFacetFields.forEach(field => {
			if (!(field in facetSelections)) {
				facetSelections[field] = undefined
			}
		})

		const { ...cleanFacetSelections } = facetSelections

		TagManager.dataLayer({
			dataLayer: {
				'event': 'facets_selections',
				...cleanFacetSelections
			}
		})
	}


	const displayAllFilters = (reference: any) => {
		if (selectedReferences.includes(reference))
			return setSelectedReferences(prevState => prevState.filter(item => item !== reference))

		return setSelectedReferences(prevState => [...prevState, reference])
	}

	const handleClearFilter = (reference: string) => {
		const newActiveFilter = activeFilters
		if (newActiveFilter[reference]) {
			const { [reference]: x, ...activeFilters } = newActiveFilter
			dispatch(SetFacetsFilters({
				activeFilters: activeFilters,
			}))
			history.replace(`/search/${searchType}`)
		}
		props.onToggleClear()
		props.onToggleFilter()
	}

	const customClear = (reference: string) => (
		<span className='clear' onClick={() => handleClearFilter(reference)}><span style={{ color: '#a08436' }}>CLEAR</span>&nbsp; <img className='remove' src='img/icons/close.png' /></span>
	)

	useEffect(() => {
		if (filters) {
			const finalFilter = removePrefix(activeFilters)
			const changedfilter = convertObject(filters)
			const result = intersection(finalFilter, changedfilter)

			if (triggerFacetSelectionEvent) {
				handleFacetAnalytics(result)
			}
		}
	}, [filters])

	const [publicationDateData, setPublicationDateData] = useState<PublicationDateType>({
		dateFrom: 0,
		dateTo: 0,
	})

	useEffect(() => {
		if (activeFilterData.AdvanceFilter?.date_last_tasted) {
			setPublicationDateData(prevState => ({
				...prevState,
				dateFrom: activeFilterData.AdvanceFilter.date_last_tasted[0], dateTo: activeFilterData.AdvanceFilter.date_last_tasted[1],
			}))
		}
	}, [])

	const resetPublicationHandler = () => {

		setPublicationDateData(prevState => ({
			...prevState,
			dateFrom: 0,
			dateTo: 0,
		}))

		dispatch(ClearPublicationDateFilters())
	}


	const publicationDateApplyHandler = (data: any) => {
		const fromDate = data.fromDate
		const toDate = data.toDate

		const fromUnixTimestamp = new Date(fromDate).getTime()
		const toUnixTimestamp = new Date(toDate).getTime()
		dispatch(SetActiveAdvanceFilters({
			advanceFilter: { ['date_last_tasted']: [fromUnixTimestamp, toUnixTimestamp] }
		}))
	}

	const displayPublicationDateFilter = () => {
		return (
			<div>
				<TitleResetContainer>
					<span style={{ fontWeight: 'bold' }}>PUBLICATION DATE</span>
					<ResetButton onClick={() => resetPublicationHandler()} >Reset</ResetButton>
				</TitleResetContainer>
				{/* article-publication-date */}
				<PublicationDateFilter type={'normal-search'} clickApplyHandler={publicationDateApplyHandler} publicationDateData={publicationDateData} />
				<SectionSeparator position={'horizontal'} ></SectionSeparator>
			</div>
		)
	}

	const adjustedFilterTitles = () => {
		const filterTitles = getFilterKey(filters, 'search').map((reference: any) => getFilterTitle(reference))
		const latestAuthorIndex = filterTitles.indexOf('Latest author')

		if (latestAuthorIndex !== -1) {
			const reviewedByIndex = filterTitles.indexOf('Reviewed by')
		
			if (reviewedByIndex !== -1) {
				filterTitles.splice(reviewedByIndex, 1)
			}
		
			filterTitles.splice(latestAuthorIndex + 1, 0, 'Reviewed by')
		
			const countryIndex = filterTitles.indexOf('Country')
		
	
			if (countryIndex !== -1) {
				filterTitles.splice(countryIndex, 1)
			}
		
			// Insert 'Country' right after 'Reviewed by'
			filterTitles.splice(latestAuthorIndex + 2, 0, 'Country')
		}

		return filterTitles
	}

	return (
		<GeneratedFiltersContainer>
			{getFilterKey(filters, 'search').map((reference: any, key: number) => {
				const title = adjustedFilterTitles()[key]
				return (
					<div key={key}>
						{searchType === APP_CONFIG.SEARCH.TYPES.WINE ?
							<div className='title'>
								{title}
								{title === 'Certified' && <img className='logo' src='img/icons/certified-icon.png' alt='Certified Icon' />}
								{activeFilters[reference] && customClear(reference)}
							</div>
							:
							<div className='title'>{getFilterTitle(reference)} {activeFilters[reference] && customClear(reference)}</div>
						}
						<div className='filter-options-container'>
							{
								getFilters(filters[reference])
									.map(filterOption => {
										newFilterOptions = getFilterOptions(filterOption, reference)
										if (!selectedReferences.includes(reference)) {
											return newFilterOptions?.slice(0, APP_CONFIG.SEARCH.MAX_TO_DISPLAY_ON_LEFT).map(data => (
												<div key={data.label}>
													<div className='selector-container'>
														<input onChange={(e) => toggleFilter(e, reference)} type='checkbox' id={lowerCaseId(`${reference}:${data.label}`)} checked={IsChecked(reference, lowerCaseId(`${reference}:${data.label}`), activeFilters)} />
														<label htmlFor={lowerCaseId(`${reference}:${data.label}`)}>{data.label.length > 30 ? data.label.slice(0, 28) + '...' : data.label}</label>
													</div>
													<div className='count-container'>
														({data.value})
													</div>
												</div>
											))
										}

										return newFilterOptions.map(data => (
											<div key={data.label}>
												<div className='selector-container'>
													<input onChange={(e) => toggleFilter(e, reference)} type='checkbox' id={lowerCaseId(`${reference}:${data.label}`)} checked={IsChecked(reference, lowerCaseId(`${reference}:${data.label}`), activeFilters)} />
													<label htmlFor={lowerCaseId(`${reference}:${data.label}`)}>{data.label.length > 30 ? data.label.slice(0, 28) + '...' : data.label}</label>
												</div>
												<div className='count-container'>
													({data.value})
												</div>
											</div>
										))
									})}
						</div>
						{newFilterOptions.length > APP_CONFIG.SEARCH.MAX_TO_DISPLAY_ON_LEFT ? (
							<div className='view-all'>
								<span onClick={() => displayAllFilters(reference)}>{selectedReferences.includes(reference) ? 'View less' : 'View all'}</span>
							</div>
						) : (<></>)}
						<SectionSeparator position={'horizontal'} ></SectionSeparator>
						{reference === 'name' && displayPublicationDateFilter()}
					</div>
				)
			})}

		</GeneratedFiltersContainer>
	)
}

export default FilterOptions