import React, { MouseEventHandler } from 'react'
import { Container, FlexContainer, FlexStatusContainer, HalfWidth, HalfWidthFlex, InnerContainer, ItalicText, MessageContainer, MessageText, MessageTitle, ResendButton, SenderRecipientInfo, StyledDiv, SubTitle, TextGreen, Title } from '../../styles'

const Card = ({
	sFirstName,
	sLastName,
	sEmail,
	rFirstName,
	rLastName,
	rEmail,
	message,
	created,
	sendDate,
	status,
	activatedAt,
	onResend,
	hasButton = true
}: {
	sFirstName: string;
	sLastName: string;
	sEmail: string;
	rFirstName: string;
	rLastName: string;
	rEmail: string;
	message: string;
	created: string;
	sendDate: string;
	status: string;
	activatedAt: string
	onResend: MouseEventHandler,
	hasButton: boolean
}): JSX.Element => {
	return (
		<Container>
			<InnerContainer activatedAt={activatedAt}>
				<FlexContainer>
					<HalfWidth>
						<Title>Sender</Title>
						<SenderRecipientInfo>
							<SubTitle>{sFirstName}</SubTitle>
							<SubTitle>{sLastName}</SubTitle>
							<SubTitle></SubTitle>
						</SenderRecipientInfo>
						<SubTitle>{sEmail}</SubTitle>
					</HalfWidth>
					<HalfWidth>
						<Title>Recipient</Title>
						<SenderRecipientInfo>
							<SubTitle>{rFirstName}</SubTitle>
							<SubTitle>{rLastName}</SubTitle>
							<SubTitle></SubTitle>
						</SenderRecipientInfo>
						<SubTitle>{rEmail}</SubTitle>
					</HalfWidth>
				</FlexContainer>

				<MessageContainer>
					<MessageTitle>Message</MessageTitle>
					<MessageText>{message}</MessageText>
				</MessageContainer>

				<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
					<div>
						<ItalicText>Created: {created} </ItalicText>
						<ItalicText>Send date: {sendDate} </ItalicText>
					</div>

					{hasButton && (
						<div style={{ alignSelf: 'flex-end', marginTop: 'auto' }}>
							<ResendButton onClick={onResend}>RESEND EMAIL</ResendButton>
						</div>
					)}
				</div>

				{activatedAt && <FlexStatusContainer>
					<HalfWidthFlex>Status: <TextGreen>{status}</TextGreen></HalfWidthFlex>
					<HalfWidth>
						<SenderRecipientInfo>
							<HalfWidthFlex>Activated at: <TextGreen>{activatedAt}</TextGreen></HalfWidthFlex>
						</SenderRecipientInfo>
					</HalfWidth>
				</FlexStatusContainer>}

			</InnerContainer>
		</Container>
	)
}

export default Card
