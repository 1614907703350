import React from 'react'
import { Button, ButtonContainer, FormContainer, Input, InputLabel, TitleModal, URL, Wrapper } from '../../styles'

interface SecurityModalProps {
    onLogin: () => void;
    onCancel: () => void;
	userName: string;
	passWord: string;
	onChangeUsername: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onChangePassword: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const SecurityModal: React.FC<SecurityModalProps> = ({ onLogin, onCancel, userName, passWord, onChangeUsername, onChangePassword }) => {
	return (
		<Wrapper>
			<FormContainer>
				<TitleModal>Sign in</TitleModal>
				<URL>Admin Gift Subscription</URL>
				<InputLabel>Username</InputLabel>
				<Input type="text" placeholder="Enter your username" value={userName} onChange={onChangeUsername}/>
				<InputLabel>Password</InputLabel>
				<Input type="password" placeholder="Enter your password" value={passWord} onChange={onChangePassword}/>
				<ButtonContainer>
					<Button className="login" onClick={onLogin}>Login</Button>
					<Button className="cancel" onClick={onCancel}>Cancel</Button>
				</ButtonContainer>
			</FormContainer>
		</Wrapper>
	)
}

export default SecurityModal