import React, { ReactElement } from 'react'
import { Container, WidgetBody, WidgetHeader, WidgetInfo, WidgetSubInfo } from './styles'
import { isMobile } from 'react-device-detect'
import { sanitizeUrl } from '@braintree/sanitize-url'

function ContactUs(): ReactElement {

	return (
		<>
			<div className="mnmd-block mnmd-block--fullwidth mnmd-block--contiguous page-heading page-heading--has-background">
				<div className="container">
					{isMobile ? <h2 className="page-heading__title" style={{ paddingTop: '20px' }}>Contact Us</h2> : <h2 className="page-heading__title">Contact Us</h2>}
				</div>
			</div>

			<div className="mnmd-block mnmd-block--fullwidth single-entry">
				<div className="container">
					<Container>
						{/* <HeaderParag>
						If you have a problem, general question, wish to point out a content error, want to send a media inquiry or have another issue, please fill in the form below.
					</HeaderParag> */}
						<div style={{ marginTop: '-5px' }}>
							<WidgetHeader><span>OTHER CONTACT DETAILS</span></WidgetHeader>
							<WidgetBody>
								<WidgetInfo>EMAIL</WidgetInfo>
								<WidgetSubInfo><a href={sanitizeUrl('mailto:support@robertparker.com')}>support@robertparker.com</a></WidgetSubInfo>
								<WidgetInfo>ADDRESS</WidgetInfo>
								<p>
									ROBERT PARKER WINE ADVOCATE <br />
									6th West 18th Street <br />
									5th Floor, New York <br />
									NY 10011 <br />
									United States
								</p>
							</WidgetBody>
						</div>
					</Container>
					{/* <DetailContainer size={'14px'} >
					<FormFieldContianer>
						<div className='form-label'>Name</div>
						<div className='form-value'><input type='text' /></div>
					</FormFieldContianer>

					<FormFieldContianer>
						<div className='form-label'>Email</div>
						<div className='form-value'><input type='email' /></div>
					</FormFieldContianer>

					<FormFieldContianer>
						<div className='form-label-required'>Choose a category</div>
						<div className='form-value'>
							<select id='category' name='category' >
								<option value='Select'>General enquiry</option>
							</select>
						</div>
					</FormFieldContianer>

					<FormFieldContianer>
						<div className='form-label'>Message</div>
						<div className='form-value'><textarea rows={8} /></div>
					</FormFieldContianer>
				</DetailContainer>
				<ButtonsContainer>
					<SendMessage>SEND MESSAGE</SendMessage>
				</ButtonsContainer> */}
				</div>
			</div>
		</>
	)
}

export default ContactUs