/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useEffect, useState } from 'react'
import { ClearUserSession, IsUserAuthenticated, SaveAuthenticationSession, TranslationsUtil } from '../../../utils'
import englishTranslation from './translations/en.json'
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu'
import { useGetUserDetailsByIdQuery, useGetUserLoginMutation } from '../../../app/services'

// Hooks
import { useAppSelector } from '../../../app/hooks'

// Store
import { Authentication as AuthFromStore, UpdateUserAuthDetails } from '../../../app/slices/Authentication'
import { useDispatch } from 'react-redux'

import { useHistory, useLocation } from 'react-router-dom'

const english: LanguageDefinitions = englishTranslation
const translationUtil = new TranslationsUtil(english)

import { Link } from 'react-router-dom'
import { clearCookie, setCookie } from '../../../utils/CookieUtils'
import SearchBoxAutoComplete from '../nav-bar/search/SearchBoxAutoComplete'
import { selectTab } from '../../../app/slices/Payment'
import TagManager from 'react-gtm-module'
import { useBasePath } from '../../content/Common'
import LoginForms from '../../content/Common/Login/LoginForms'
import CommonModalContainer from '../../modals/ModalContainer'
import { dataCySelector } from '../../../app/services/constant'
import { ClearSelectedWines } from '../../../app/slices/SearchResults'
import { removeAccessLocalStorage, setAccessLocalStorage } from '../../../utils/ValidateAccessToken'
import { getCSURFToken } from '../../../utils/CSRFToken'
import { deviceType } from '../../../helper/constants'
import LoadingOverlay from '../../../components/Loading-overlay'

function UserLogin(): ReactElement {
	const translate = translationUtil.getTranslator()
	const [fullName, setFullName] = useState('')
	const [isOpen, setIsOpen] = useState(false)
	const [isLoginModalOpen, setIsLoginModalOpen] = useState(false)
	const authentication = useAppSelector(AuthFromStore)
	const customerId = authentication.User?.user?._id
	const accessToken = localStorage.getItem('accessToken')
	const { data } = useGetUserDetailsByIdQuery(customerId, { skip: !accessToken || !customerId })
	const subscriptionType = data?.data?.subscription?.type
	const [userLoginMutation, { data: loginUserData, isLoading: loginUserIsLoading, isSuccess: isLoginUserSuccess }] = useGetUserLoginMutation()

	const dispatch = useDispatch()
	const history = useHistory()
	const pathname = useBasePath()

	const location = useLocation<{ groupState?: any }>()
	const { groupState } = location.state || {}

	const execLogout = () => {
		clearCookie()
		ClearUserSession(dispatch)
		dispatch(ClearSelectedWines())
		history.push('/')
		window.location.reload()
		removeAccessLocalStorage()
	}

	const loginUser = async () => {
		try {
			const csrf_token = await getCSURFToken()
			const loginPayload = {
				username: groupState?.email,
				password: groupState?.password,
				device: deviceType()
			}

			await userLoginMutation({ params: loginPayload, token: csrf_token }).unwrap()

		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		if(groupState)
			loginUser()
	}, [groupState])

	useEffect(() => {
		if (loginUserData && isLoginUserSuccess) {
			if (loginUserData.success) {
				SaveAuthenticationSession(
					'user',
					loginUserData.data.accessToken,
					loginUserData.data.accessTokenExpiresAt,
					loginUserData.data.refreshToken,
					loginUserData.data.refreshTokenExpiresAt,
					loginUserData.data.client._id,
					loginUserData.data.user._id
				)

				setAccessLocalStorage(loginUserData.data.accessToken, loginUserData.data.accessTokenExpiresAt, loginUserData.data.refreshToken, loginUserData.data.refreshTokenExpiresAt)

				dispatch(UpdateUserAuthDetails({
					token: loginUserData.data.accessToken,
					tokenExpiry: loginUserData.data.accessTokenExpiresAt,
					refreshToken: loginUserData.data.refreshToken,
					refreshTokenExpiry: loginUserData.data.refreshTokenExpiresAt,
					clientId: loginUserData.data.client._id,
					country: loginUserData.data.country,
					userId: loginUserData.data.user._id
				}))
				setCookie('username', groupState.email, true)
				history.push({
					pathname: '/join-group-success',
					state: { manager: groupState.invitor }
				})
			}
		}
	}, [loginUserData, isLoginUserSuccess])
	

	useEffect(() => {
		if (data) {
			if(data.success){
				if (authentication.User.accessToken) {
					const objectData = JSON.parse(JSON.stringify(data.data).replace(/</g, '\\u003c'))
					setFullName(`${objectData?.profile?.name?.first_name} ${objectData?.profile?.name?.last_name}`)
	
				}
			} else {
				if(data.message === 'Access token is invalid.' || data.message === 'Token missing or malformed.') {
					clearCookie()
					ClearUserSession(dispatch)
					dispatch(ClearSelectedWines())
					window.location.href = '/login?AuthExpired=true'
				}
			}
			
		}
	}, [data, authentication.User.accessToken])

	useEffect(() => {
		if(pathname === '/login') {
			setIsOpen(true)
		}
	}, [pathname])
	
	const triggerSubscription = () => {
		TagManager.dataLayer({
			dataLayer: {
				'event': 'subscribe_now_btn',
				'page_origin': pathname
			}
		})
	}

	// const getLoginButton = () => (
	// 	<div className="col-3 text-right">
	// 		<Link onClick={triggerSubscription} to={'/subscriptions'} className="btn btn-subscribe" {...dataCySelector('subscribe-now-btn')}><img src='/img/icons/elements-icons-mid-book.png' /> &ensp;
	// 			<span>{translate('SUBSCRIBE NOW')}</span>
	// 		</Link> &nbsp;
	// 		<div onClick={()=> setIsLoginModalOpen(true)} className="btn btn-default" data-toggle="modal" {...dataCySelector('login-btn')}><i className="mdicon mdicon-person mdicon--first"></i>
	// 			<span>{translate('LOGIN')}</span>
	// 		</div>
	// 	</div>
	// )

	const getLoginButton = () => (
		<div className="d-flex col-3 text-right">
			<Link onClick={triggerSubscription} to={'/subscriptions'} className="btn btn-subscribe btn-subscribe-style" {...dataCySelector('subscribe-now-btn')}><img className='subscribe-icon' src='/img/icons/elements-icons-mid-book.png' /> &ensp;
				<span>{translate('SUBSCRIBE NOW')}</span>
			</Link> &nbsp;
			<div onClick={()=> setIsLoginModalOpen(true)} className="btn btn-default btn-login-style" data-toggle="modal" {...dataCySelector('login-btn')}><i className="mdicon mdicon-person mdicon--first"></i>
				<span>{translate('LOGIN')}</span>
			</div>
		</div>
	)

	const execChangePage = (url: string) => {
		dispatch(selectTab(0))
		window.location.href = url
	}

	const getUserMenu = () => (
		<div className="col-2 text-right user-menu" {...dataCySelector('user-menu-btn')}>
			<DropdownMenu trigger={fullName}>
				{/* <DropdownMenu trigger={<div><i className="mdicon mdicon-person mdicon--first" />{fullName}</div>}> */}
				<DropdownItemGroup>
					<DropdownItem onClick={() => { execChangePage('/my-profile/edit-profile') }} {...dataCySelector('my-profile-btn')}>MY PROFILE</DropdownItem>
					<DropdownItem onClick={() => { execChangePage('/my-subscription/subscription-details') }} {...dataCySelector('manage-subscription-btn')}>MANAGE SUBSCRIPTION</DropdownItem>
					{subscriptionType === 'commercial' ? <DropdownItem onClick={() => { execChangePage('/my-company/manage-group') }} {...dataCySelector('manage-company-btn')}>MANAGE COMPANY</DropdownItem> : ''}
					{/* <DropdownItem>REFERRALS</DropdownItem> */}
					<DropdownItem onClick={execLogout} {...dataCySelector('logout-btn')}>LOGOUT</DropdownItem>
				</DropdownItemGroup>
			</DropdownMenu>
		</div>
	)

	return (
		<div className="header-main d-none d-lg-block">
			<div className="container">
				<div className="row row--flex row--vertical-center">
					<div className="col-3">
						<div className="header-logo text-center">
							<Link to={'/'}>
								<img src="img/logo-mark-color.png" alt="logo" width="200" />
							</Link>
						</div>
					</div>
					{!IsUserAuthenticated() ?
						<>
							<div className="col-6" style={{ marginTop: '30px', paddingLeft: '0', paddingRight: '0' }}>
								<SearchBoxAutoComplete />
								<div style={{ paddingTop: '10px', textAlign: 'right', cursor: 'pointer', fontSize: '14px' }}>
									<a href='/advanced-search/wine'>
										<span >{translate('ADVANCED SEARCH')}</span>
									</a>
								</div>
							</div>
							{getLoginButton()}

						</>
						:
						<>
							<div className="col-7" style={{ marginTop: '30px', paddingLeft: '0', paddingRight: '0' }}>
								<SearchBoxAutoComplete />
								<div style={{ paddingTop: '10px', textAlign: 'right', cursor: 'pointer', fontSize: '14px' }}>
									<a href='/advanced-search/wine'>
										<span >{translate('ADVANCED SEARCH')}</span>
									</a>
								</div>
							</div>
							{getUserMenu()}
						</>
					}
				</div>
			</div>
			<CommonModalContainer isOpen={isLoginModalOpen} onClose={()=> setIsLoginModalOpen(false)}>
				<LoginForms setIsOpen={setIsLoginModalOpen} />
			</CommonModalContainer>

			<CommonModalContainer isOpen={isOpen} onClose={()=> setIsOpen(false)}>
				<LoginForms setIsOpen={setIsOpen} />
			</CommonModalContainer>
			{loginUserIsLoading && <LoadingOverlay />}
		</div>
	)
}

export default UserLogin