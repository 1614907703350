import styled from 'styled-components'

export const Menu = { // Enums
	Subcription_Login: 1,
	Technical_Issue: 2,
	Site_Content_Features: 3,
	Search: 4,
	Mobile_App_Issue: 5,
}

export const MenuOptionContainer = styled.div`
	@media(max-width: 992px) {
		display: none;
	}
`

export const MenuOption = styled.button<{ selected: boolean }>`
	height: 21px;
	width: 170px;
	background-color: #fff;
	font-family: Libre Franklin;
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
	color: #333;
	// margin-right: 10px;
	padding: 0px;

	&:hover {
		color: #a08436;
	}

	border: none;
	// border-bottom: ${(props) => (props.selected ? '2px solid #a08436' : 'none')};
	& div.line {
		height: 4px;
		background-color: ${(props) => (props.selected ? '#a08436 !important' : '#fafafa')};
		width: 100%;
	}
`

export const TabbedMenu = styled.div`	
	// background-color: red;
	// height: 100px;
	width: 100%;
	display: flex;
	flex-wrap: no-space;
	overflow-x: scroll;

	::-webkit-scrollbar {
		display: none;
	  }
	  
`
export const MenuOptionMobile = styled.button<{ selected: boolean }>`
	// height: 21px;
	width: 170px;

	background-color: #fff;
	font-family: Libre Franklin;
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
	color: #333;
	margin-right: 10px;
	margin-top: 5px;
	padding: 0;

	&:hover {
		color: #a08436;
	}

	border: none;
	// border-bottom: ${(props) => (props.selected ? '2px solid #a08436' : 'none')};

	& div.line {
		height: 4px;
		background-color: ${(props) => (props.selected ? '#a08436 !important' : '#fafafa')};
		width: 200px;
		margin: 0;
	}
`

const commonTextStyle = `
	font-family: Libre Franklin;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;

`
export const PageTitle = styled.div`
	width: 100%;
	margin: 40px 134px 20px 0px;
	font-size: 40px;
	font-weight: bold;
	color: #333;
	${commonTextStyle}
`
export const QAContainer = styled.div`
	width: 100%;

	& span.jump-question {
		width: 100%;
		height: 21px;
		// margin: 0 0 6px;
		font-family: Libre Franklin;
		font-size: 14px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.2;
		letter-spacing: normal;
		color: #333;
	}

	& select.jump-question-select {
		width: 100%;
		height: 33px;
		margin: 6px 0 0;
		padding: 6px 12px;
		border-radius: 8px;
		border: solid 1px #ccc;
		background-color: var(--white-three);
	}
`
export const QuestionContainer = styled.div`
	width: 310px;
`
export const AnswerContainer = styled.div`
	width: 750px;
`
export const AnswerContainerMobile = styled.div`
	width: 100%;
	padding-top: 40px
`
export const Row = styled.div`
	display: flex;
	justify-content: space-between;
`
export const Question = styled.div`
	margin: 0 0 20px;
	font-size: 16px;
	font-weight: normal;
	color: #e01a00;
	${commonTextStyle}
`
export const QuestionTitle = styled.div`
	width: 100%;
	margin: 0 0 10px;
	font-size: 24px;
	font-weight: bold;
	color: #333;
	${commonTextStyle}
`
export const QuestionAnswer = styled.div`
	width: 100%;
	margin: 10px 0 40px;
	font-size: 16px;
	font-weight: normal;
	color: #333;
	${commonTextStyle}

	& .image {
		text-align: center;
	}
`

export const CustomUL = styled.ul`
	padding-left: 30px;
	& li {
		margin-bottom: 1em;
		padding-left: 5px;
`

export const UL50PX = styled.ul`
	padding-left: 50px;
	& li {
		margin-bottom: 1em;
		padding-left: 5px;
`

export const DefaultPicture = styled.div`
	width: 750px;
	height: 300px;
	margin: 20px 31px 40px 0;
	border: solid 4px var(--white-three); 
	border-style: dotted;
`
export const DefaultPictureMobile = styled.div`
	width: 100%;
	height: 200px;
	margin: 20px 31px 40px 0;
	border: solid 4px var(--white-three); 
	border-style: dotted;
`

export const TextStyle1 = styled.span`
	font-weight: bold;
`
export const TextStyle2 = styled.span`
	font-style: italic;
`
export const TextStyle3 = styled.span`
	color: #e01a00;
`
export const LineBreak = styled.div`
	margin: 0 0 25px;
`

export const TextListNumbered = styled.ol`
	margin-left: 1rem;

	& li {
		margin-bottom: 0.5rem;
	}
`
