import styled from 'styled-components'

export const FilterContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	align-self: center;
	padding-top: 30px;
	padding-bottom: 30px;
`

export const FilterWrapper = styled.div`
	display: flex;
	justify-content: center;
	gap: 16px;
`

export const SearchContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
	position: relative;
`

export const SearchLabel = styled.div`
	font-weight: 600;
`

export const SearchInputWrapper = styled.div`
	position: relative;
`

export const SearchIcon = styled.i`
	position: absolute;
	left: 10px;
	top: 50%;
	transform: translateY(-50%);
	color: black;
	font-size: 17px;
`

export const SearchInput = styled.input`
	padding: 8px 8px 8px 30px !important;
	border: 1px solid #ccc;
	border-radius: 6px !important;
	outline: none;
	width: 260px !important;
	height: 33px !important;
`

export const Dropdown = styled.select`
	padding: 6px;
	border: 1px solid #ccc;
	outline: none;
	cursor: pointer;
	height: 33px;
	width: 260px;
	border-radius: 6px;
`

export const ResetButton = styled.div`
	cursor: pointer;
	font-weight: 600;
	color: #4e76a2;
	padding-left: 10px;
`

export const GlobalStyles = styled.div`
    body {
        background-color: #2d2d2d;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
        margin: 0;
		position: absolute;
		top: 0;
		right: 0;
    }
`

export const NotificationContainer = styled.div`
    background-color: #f0f9eb;
    color: #2d6a4f;
    border: 1px solid #c7e4b5;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    padding: 1rem;
    width: 100%;
    max-width: 768px;
`

export const Icon = styled.i`
    margin-right: 0.5rem;
`

export const CloseButton = styled.div`
    margin-left: auto;
    color: #6b7280;
    cursor: pointer;
`

export const Tooltip = styled.div`
    visibility: hidden;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%; /* Position above the button */
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;

    ${CloseButton}:hover & {
        visibility: visible;
        opacity: 1;
    }
`
